<template>
  <div class="exercisesDetail">
    <div class="box">
      <div class="subject">
        <div class="content">
          练习题题目:
          <span class="content-span">题型：{{ queType[queList[current].questionType] }}</span>
          <span class="content-span">类型：{{ queCategory[queList[current].type] }}</span>
          <span class="content-span">难度：{{ queDifficulty[queList[current].level] }}</span>
          <el-button v-if="queList[current].questionType!==2&&queList[current].questionType!==3" style="float: right;margin-right: 20px" type="text" @click="downLoad">下载附件</el-button>
          <div class="text">
            <div class="detail" v-html="queList[current].questionInformation" />
          </div>
        </div>
      </div>
      <div class="answer">
        <div v-if="queList[current].questionType==3" class="content">
          我的答案:
          <div class="input-c" style="padding: 10px 0;"><el-select v-model="queList[current].studentAnswer" placeholder="请选择答案" size="small">
            <el-option
              v-for="dict in answerList"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select></div>
        </div>
        <div v-else class="content">
          <div>
            我的答案:
            <div v-if="queList[current].questionType!==2&&queList[current].questionType!==3" style="float: right;">
              <FileUpload :key="queList[current].id" :btn-value="'上传附件'" :value="queList[current].urlModel" :limit="1" :type="{type:5}" @input="inputList" />
            </div>
            <div style="padding: 20px 0;">
              <el-input
                v-model="queList[current].studentAnswer"
                class="answer-textarea"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 5}"
                :rows="2"
                resize="none"
                :maxlength="500"
                :show-word-limit="true"
                placeholder="填空题答案请用英文逗号(',')分隔开"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="queList[current].publishType!==0" class="answer">
        <div class="content">
          <div>
            答案信息:
            <el-button type="text" style="float: right" @click="downLoadAnswer">下载附件</el-button>
          </div>
          <div class="text" v-html="queList[current].answerInformation" />
          <div v-if="queList[current].questionType!==2&&queList[current].questionType!==3" class="btn" />
        </div>
      </div>
      <div class="page">
        <div class="btn">
          <el-button type="primary" :class="queList[current].studentAnswer===null||queList[current].studentAnswer=='' ? 'greyBtn' : ''" size="mini" round @click="saveCurrent(1)">保存</el-button>
          <el-button type="primary" :class="current===0 ? 'greyBtn' : ''" size="mini" round @click="previous">上一题</el-button>
          <el-button type="primary" :class="current===queList.length-1 ? 'greyBtn' : ''" size="mini" round @click="next">下一题</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { submitExervises } from '../../api/course'
import FileUpload from '../../components/FileUpload'
export default {
  name: 'ExercisesDetail',
  components: { FileUpload },
  created() {
    this.queList = this.$route.params.list
    console.log(this.queList)
    this.dealWithUrls()
    this.current = this.$route.params.index
  },
  computed: {
    ...mapState({
      answerList: state => state.dict.dictTree.work_answer['arr'],
      queType: state => state.dict.dictTree.ea_question_type['obj'],
      queCategory: state => state.dict.dictTree.ea_question_category['obj'],
      queDifficulty: state => state.dict.dictTree.ea_question_difficulty['obj']
    })
  },
  data() {
    return {
      current: '',
      queList: []
    }
  },
  methods: {
    dealWithUrls() {
      this.queList.forEach(item => {
        if (item.studentUrls !== null && item.studentUrls !== '') {
          item.urlModel = [{
            url: item.studentUrls,
            name: '附件'
          }]
        } else {
          item.urlModel = 'undefined'
        }
      })
    },
    // 上传附件的子传父
    inputList(data) {
      console.log(data)
      if (data.length > 0) {
        this.queList[this.current].studentUrls = data.map(i => i.url).toString()
      } else {
        this.queList[this.current].studentUrls = ''
      }
    },
    downLoadAnswer() {
      if (this.queList[this.current].questionUrls == '' || this.queList[this.current].questionUrls == null || this.queList[this.current].questionUrls == undefined) {
        this.$message.warning('该试题没有附件')
      } else {
        this.$download.name(this.queList[this.current].questionUrls)
      }
    },
    downLoad() {
      if (this.queList[this.current].answerUrls == '' || this.queList[this.current].answerUrls == null || this.queList[this.current].answerUrls == undefined) {
        this.$message.warning('该试题没有答案附件')
      } else {
        this.$download.name(this.queList[this.current].answerUrls)
      }
    },
    previous() {
      if (this.current === 0) {
        return
        // this.$message.warning('当前已是第一题')
      } else {
        const answer = this.queList[this.current].studentAnswer
        const answerUrls = this.queList[this.current].studentUrls
        console.log(answer)
        console.log(answerUrls)
        if ((answer !== '' && answer !== null) || (answerUrls !== '' && answerUrls !== null)) {
          const obj = {
            id: this.queList[this.current].publishId,
            answerInformation: answer,
            answerUrls: answerUrls
          }
          submitExervises(obj).then(res => {
            if (res.code === 200) {
              this.timelyEcho(answerUrls)
              this.current--
            }
            console.log(res)
          })
        } else {
          this.current--
        }
      }
    },
    // 及时回显
    timelyEcho(answerUrls) {
      if (answerUrls !== '' && answerUrls !== null) {
        console.log(122)
        this.queList[this.current].urlModel = [{ url: answerUrls, name: '附件' }]
      } else {
        console.log(12)
        this.queList[this.current].urlModel = ''
      }
      console.log(this.queList)
    },
    next() {
      console.log(this.queList)
      if (this.current === this.queList.length - 1) {
        return
        // this.$message.warning('当前已是最后一题')
      } else {
        const answer = this.queList[this.current].studentAnswer
        const answerUrls = this.queList[this.current].studentUrls
        console.log(answer)
        console.log(answerUrls)
        if ((answer !== '' && answer !== null) || (answerUrls !== '' && answerUrls !== null)) {
          const obj = {
            id: this.queList[this.current].publishId,
            answerInformation: answer,
            answerUrls: answerUrls
          }
          submitExervises(obj).then(res => {
            console.log(res)
            if (res.code === 200) {
              this.timelyEcho(answerUrls)
              this.current++
            }
          })
        } else {
          this.current++
        }
      }
    },
    // 保存
    saveCurrent(data) {
      // data是否提示，1提示，0不提示
      const obj = {
        id: this.queList[this.current].publishId,
        answerInformation: this.queList[this.current].studentAnswer,
        answerUrls: this.queList[this.current].studentUrls
      }
      if (obj.answerInformation === null || obj.answerInformation === '') {
        return
      }
      submitExervises(obj).then(res => {
        console.log(res)
        if (res.code === 200 && data === 1) {
          this.$message.success('保存成功')
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
    .exercisesDetail{
        .box{
            margin: 17px 0px;
            width: 770px;
            height: 680px;
            /*background: #FFFFFF;*/
            border-radius: 6px;
            >.subject{
                //padding-top: 19px;
            }
            .title{
                text-align: left;
                margin-left: 19px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
            .content{
              text-align: left;
              padding: 15px;
                margin: 18px 30px;
                width: 730px;
                min-height: 200px;
                background: white;
                border-radius: 10px;
              .content-span{
                  display: inline-block;
                padding: 0 20px;
              }
                .text{
                    padding: 20px 20px 0 20px;
                    margin: 10px 0;
                    height: 165px;
                    overflow: auto;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    .topic{
                        font-size: 18px;
                    }
                }
                .btn{
                    margin-top: 10px;
                    text-align: right;
                    margin-right: 20px;
                    span{
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #999999;
                        padding-right: 64px;
                    }
                }
            }
            >.page{
                text-align: right;
                margin-right: 24px;
                span{
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }
    .greyBtn{
      color: #fff;
      background-color:  rgba(153, 153, 153, 0.47);
      border-color:  rgba(153, 153, 153, 0.47);
    }
</style>
